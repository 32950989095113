.gallery-container  {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
    margin:5rem 0rem;
}
.image-container {
    display: flex;
    gap:1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    width:90%;
    margin:1rem auto;
}
.image img {
    width:150px;
    height:150px;
    background-size: cover;
    object-fit: cover;
    transition: .3s;
    border-radius: 8px;
}
.image img:hover {
    transform: scale(1.1);
    transition: .3s;
    cursor: pointer;
}