
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@1&family=Poppins:wght@200&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
