footer {
    background: #394867;
    height: 100%;
    position: relative;
}
footer:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    background: url(../../assets/wave.svg);
    height: 150px;
    width: 100%;
    background-size: cover;
}
.footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top:5rem;
}
.footer-right {
    margin-top:5rem;
}
.footer-right iframe {
    margin-top: 2rem;
    height: 250px;
    width: 100%;
}
.footer-left-animation {
    height: 400px;
    width: 100%;
}
.footer-content h2 {
    font-size: 2rem;
    color:white;
    margin-bottom: 3rem;
    text-align: center;
}
.footer-right-content {
    display: flex;
    justify-content: center;
    gap:3rem;
    background: white;
    padding: 2rem 1rem 3rem 1rem;
    border-radius: 12px;
}
.telefon, .email {
    display: flex;
    gap:1rem;
    height: 30px;
}
.telefon-right a {
    text-decoration: none;
    color:black;
}
.telefon-left, .email-left {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    height: 50px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #57C5B6;
    border-radius: 5px;
    color:#57C5B6;
}
.telefon-right p, .email-right p {
    font-size:1.1rem;
    margin: 0;
    padding: 0;
}
@media(max-width:1181px) {
    footer {
        height: 100%;
    }
    .footer-content {
        flex-direction: column;
    }
    .footer-left-animation {
        height: 300px;
    }
    .footer-right-content  {
        margin-bottom: 2rem;
    }
}
@media(max-width:607px) {
    .footer-left-animation {
        height: 200px;
    }
    .footer-content h2 {
        font-size:1.5rem;
        text-align: center;
    }
    .footer-right-content {
        flex-direction: column;
        padding: 2rem 1rem 3.5rem 1rem;
    }
    .footer-right {
        margin-top:1rem;
    }
    .footer-content {
        width: 90%;
        margin: 0 auto;
    }
}